<template>
  <div class="container">
    <div class="card card-body mt-5" style="background-color:#e2e2e2;max-width:32rem;">
      <div>
        <div class="text-center mb-3">
          <h4>Importante!!!</h4>
        </div>
        <div v-if="config.API_URLV2 === 'https://apim-bikeregistrada.azure-api.net'">
          <b-badge variant="success"> Ambiente atual: Produção </b-badge>
          <div>
            <b-badge variant="primary"> Versão: {{ version }}</b-badge>
          </div>
        </div>
        <div v-else>
          <b-badge variant="warning"> Ambiente atual: Desenvolvimento </b-badge> <br />
          <b-badge variant="primary"> Versão: {{ version }}</b-badge>
        </div>
      </div>

      <div>
        <small>
          Pagamento modo teste:
          <strong>{{ config.IUGU_TEST_MODE }}</strong></small>
      </div>
      <div>
        <small>
          Traker marketing:
          <strong>{{ config.ENABLE_MARKETING_TRACKERS }}</strong></small>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../config";
import { version } from "../../package";

export default {
  data() {
    return {
      config,
      version,
    };
  },
};
</script>

<style lang="scss" scoped></style>
